import { useRouter } from 'next/router';
import { useCallback, useState, useEffect } from 'react';
import { config } from '../../config';
import { useGetUser } from "../../hooks";

import { routes } from '../../routes/routes';
import styles from './layout.module.css';

export function MainLayout({ children }) {

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch({ type: FetchState.FETCH_INIT });
    try {
      await api.createSession(email, password);
      const data = await api.getAccount();
      dispatch({ type: FetchState.FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: FetchState.FETCH_FAILURE });
    }
  };

  return (
    <div className={styles.appContainer}>
      <main className={styles.appBody}>
        {children}
      </main>
    </div>
  );
}
