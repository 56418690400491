"use client"

import api from "../api/api";
import { Server } from "../utils/config";
import { Query } from 'appwrite';
import { useEffect, useReducer } from "react";

export const FetchState = {
  FETCH_INIT: 0,
  FETCH_SUCCESS: 1,
  FETCH_FAILURE: 2,
};

export const useGetGameId = (stale) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingGames: true, isErrorGames: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingGames: false,
          isErrorGames: false,
          gameId: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingGames: false, isErrorGames: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingGames: false,
    isErrorGames: false,
    gameId: '',
  });

  useEffect(() => {
    if (localStorage.getItem('memeGameId')) {
      dispatch({ type: FetchState.FETCH_SUCCESS, payload: localStorage.getItem('memeGameId') });
      return;
    }

    let didCancel = false;
    const getGames = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const data = await api.listDocuments(
          Server.gamesCollectionId,
          [
            Query.equal("Name", ["What do you meme?"])
          ]
        )
        .then((data) => {
          localStorage.setItem('memeGameId', data.documents[0].$id);
          return data.documents[0].$id;
        }, error => console.log(error));

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: data });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getGames();
    return () => (didCancel = true);
  }, [stale]);

  return [state];
};

export const useGetGameCode = (stale, gameId) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingGameCodes: true, isErrorGameCodes: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingGameCodes: false,
          isErrorGameCodes: false,
          gameCode: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingGameCodes: false, isErrorGameCodes: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingGameCodes: false,
    isErrorGameCodes: false,
    gameCode: '',
  });

  useEffect(() => {
    let didCancel = false;
    const getGameCodes = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const gameCodeData = await api.listDocuments(
          Server.gameCodesCollectionId,
          [
            Query.equal("GameID", [gameId]),
            Query.notEqual("Deleted", [true])
          ]
        )
        .then((data) => {
          return data.documents[0].Code;
        }, error => console.log(error));

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: gameCodeData });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getGameCodes();
    return () => (didCancel = true);
  }, [stale, gameId]);

  return [state];
};

export const useGetGameParticipants = (stale, gameCode) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingGameParticipants: true, isErrorGameParticipants: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingGameParticipants: false,
          isErrorGameParticipants: false,
          gameParticipants: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingGameParticipants: false, isErrorGameParticipants: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingGameParticipants: false,
    isErrorGameParticipants: false,
    gameParticipants: [],
  });

  useEffect(() => {
    let didCancel = false;
    if (!gameCode) {
      return;
    }
    const getGameParticipants = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const participantData = await api.listDocuments(
          Server.gameParticipantsCollectionId,
          [
            Query.equal("GameCode", [gameCode]),
            Query.notEqual("Deleted", [true])
          ]
        )
        .then((data) => {
          return data.documents;
        }, error => console.log(error));

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: participantData });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getGameParticipants();
    return () => (didCancel = true);
  }, [stale, gameCode]);

  return [state];
};

export const useGetGameState = (stale, gameCode) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingGameState: true, isErrorGameState: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingGameState: false,
          isErrorGameState: false,
          gameState: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingGameState: false, isErrorGameState: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingGameState: false,
    isErrorGameState: false,
    gameState: {},
  });

  useEffect(() => {
    let didCancel = false;
    if (!gameCode) {
      return;
    }
    const getGameState = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const stateData = await api.listDocuments(
            Server.memeGameStateCollectionId,
            [
              Query.equal("GameCode", [gameCode]),
              Query.notEqual("Deleted", [true])
            ]
        )
        .then((data) => {
          return data.documents[0];
        }, error => console.log(error));

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: stateData });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getGameState();
    return () => (didCancel = true);
  }, [stale, gameCode]);

  return [state];
};

export const useGetGameJudges = (stale, gameCode, recurrence) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingGameJudges: true, isErrorGameJudges: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingGameJudges: false,
          isErrorGameJudges: false,
          gameJudges: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingGameJudges: false, isErrorGameJudges: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingGameJudges: false,
    isErrorGameJudges: false,
    gameJudges: [],
  });

  useEffect(() => {
    let didCancel = false;
    if (!gameCode || !recurrence) {
      return;
    }

    const getGameJudges = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const judgesData = await api.listDocuments(
            Server.memeJudgesCollectionId,
            [
              Query.equal("GameCode", [gameCode]),
              Query.equal("Recurrence", [recurrence])
            ]
        )
            .then((data) => {
              return data.documents;
            }, error => console.log(error));

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: judgesData });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getGameJudges();
    return () => (didCancel = true);
  }, [stale, gameCode, recurrence]);

  return [state];
};

export const useGetPhotoCards = (stale) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingPhotoCards: true, isErrorPhotoCards: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingPhotoCards: false,
          isErrorPhotoCards: false,
          photoCards: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingPhotoCards: false, isErrorPhotoCards: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingPhotoCards: false,
    isErrorPhotoCards: false,
    photoCards: [],
  });

  useEffect(() => {
    let didCancel = false;
    const getPhotoCards = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const photoCardsData = await api.listDocuments(
          Server.memePhotoCardsCollectionId,
        )
          .then((data) => {
            return data.documents;
          }, error => console.log(error));

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: photoCardsData });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getPhotoCards();
    return () => (didCancel = true);
  }, [stale]);

  return [state];
};

export const useGetCaptions = (stale, gameCode, photoCardId) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingCaptions: true, isErrorCaptions: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingCaptions: false,
          isErrorCaptions: false,
          captions: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingCaptions: false, isErrorCaptions: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingCaptions: false,
    isErrorCaptions: false,
    captions: [],
  });

  useEffect(() => {
    console.log("useGetCaptions", gameCode, photoCardId)
    let didCancel = false;
    if (!gameCode || !photoCardId) {
      return;
    }
    const getCaptions = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const captionsData = await api.listDocuments(
          Server.memeGamePhotoCaptionsCollectionId,
          [
            Query.equal("GameCode", [gameCode]),
            Query.equal("PhotoCardId", [photoCardId])
          ]
        )
        .then((data) => {
          return data.documents;
        }, error => console.log(error));

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: captionsData });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getCaptions();
    return () => (didCancel = true);
  }, [stale]);

  return [state];
};

export const useGetPlayerCaption = (stale, gameCode, participantName, photoCardId) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingPlayerCaption: true, isErrorPlayerCaption: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingPlayerCaption: false,
          isErrorPlayerCaption: false,
          playerCaption: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingPlayerCaption: false, isErrorPlayerCaption: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingPlayerCaption: false,
    isErrorPlayerCaption: false,
    playerCaption: {},
  });

  useEffect(() => {
    let didCancel = false;
    if (!participantName) {
      participantName = localStorage.getItem("participantName");
    }
    if (!gameCode || !participantName || !photoCardId) {
      return;
    }
    const getPlayerCaption = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const playerCaptionData = await api.listDocuments(
          Server.memeGamePhotoCaptionsCollectionId,
          [
            Query.equal("GameCode", [gameCode]),
            Query.equal("ParticipantName", [(participantName || localStorage.getItem("participantName"))]),
            Query.equal("PhotoCardId", [photoCardId])
          ]
        )
        .then((data) => {
          return data;
        }, error => console.log(error))
        .then((data) => data.documents[0]);

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: playerCaptionData });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getPlayerCaption();
    return () => (didCancel = true);
  }, [stale]);

  return [state];
};

export const useGetScores = (stale, gameCode) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingScores: true, isErrorScores: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingScores: false,
          isErrorScores: false,
          scores: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingScores: false, isErrorScores: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingScores: false,
    isErrorScores: false,
    scores: [],
  });

  useEffect(() => {
    let didCancel = false;
    if (!gameCode) {
      return;
    }
    const getScores = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      let scoresData = [];
      try {
        scoresData = await api.listDocuments(
          Server.gamePointsCollectionId,
          [
            Query.equal("GameCode", [gameCode]),
          ]
        )
        .then((data) => {
          return data.documents;
        }, error => console.log(error))
        .then(data => data);

        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: scoresData });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getScores();
    return () => (didCancel = true);
  }, [stale, gameCode]);

  return [state];
}

export const useGetUser = () => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoadingUser: true, isErrorUser: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoadingUser: false,
          isErrorUser: false,
          user: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoadingUser: false, isErrorUser: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoadingUser: false,
    isErrorUser: true,
    data: [],
  });

  useEffect(() => {
    let didCancel = false;
    const getAccount = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const account = await api.getAccount()
          .then(account => account, error => console.log(error));
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: account });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getAccount();
    return () => (didCancel = true);
  }, []);

  return [state, dispatch];
};
