import { Client, Account, Databases, Query } from "appwrite";
import { Server } from "../utils/config";

let api = {
  sdk: {},

  provider: () => {
    if (!!api.sdk.keys) {
      return api.sdk;
    }

    let client = new Client();
    let account = new Account(client);
    let familyReunionDatabase = new Databases(client);

    client.setEndpoint(Server.endpoint).setProject(Server.project);

    api.sdk.account = account;
    api.sdk.familyReunionDB = familyReunionDatabase;

    return api.sdk;
  },

  createAccount: (email, password, name) => {
    return api.provider().account.create('unique()', email, password, name);
  },

  getAccount: () => {
    return api.provider().account.get();
  },

  createSession: async (email, password) => {
    const promise = api.provider().account.createEmailSession(email, password);

    promise.then(function (response) {
        console.log(response); // Success
    }, function (error) {
        console.log(error); // Failure
    });
  },

  deleteCurrentSession: () => {
    return api.provider().account.deleteSession('current');
  },

  createDocument: (collectionId, data, permissions = []) => {
    return api
      .provider()
      .familyReunionDB.createDocument(Server.familyReunionDatabaseID, collectionId, 'unique()', data, permissions);
  },

  listDocuments: (collectionId, queries = []) => {
    return api.provider().familyReunionDB.listDocuments(Server.familyReunionDatabaseID, collectionId, queries);
  },

  getDocumentByUserId: (collectionId, userId) => {
    return api.provider().familyReunionDB.listDocuments(
      Server.familyReunionDatabaseID,
      collectionId,
      [
        Query.limit(1),
        Query.equal('userId', userId)
      ]
    );
  },

  getMostRecentDocument: (collectionId) => {
    return api.provider().familyReunionDB.listDocuments(
      Server.familyReunionDatabaseID,
      collectionId,
      [
        Query.limit(1),
        Query.orderDesc('createdAt')
      ]
    );
  },

  updateDocument: (collectionId, documentId, data, permissions = []) => {
    return api
      .provider()
      .familyReunionDB.updateDocument(Server.familyReunionDatabaseID, collectionId, documentId, data, permissions);
  },

  deleteDocument: (collectionId, documentId) => {
    return api.provider().familyReunionDB.deleteDocument(Server.familyReunionDatabaseID, collectionId, documentId);
  },
};

export default api;
