import { MainLayout } from '../components/Layout/Layout';
import { DefaultLayout } from '../components/Layout/DefaultLayout';
import { ProtectedRoute } from '../components/Routes/ProtectedRoute';
import { StrictlyPublicRoute } from '../components/Routes/StrictlyPublicRoute';
import './_app.css';
import { config } from '../config';
import { useEffect } from 'react';

function Protector({ Component, pageProps }) {
  const {
    protectedRoute,
    strictlyPublicRoute,
  } = Component;
  if (protectedRoute) {
    return (
      <ProtectedRoute>
        <Component {...pageProps} />
      </ProtectedRoute>
    );
  } else if (strictlyPublicRoute) {
    return (
      <StrictlyPublicRoute>
        <Component {...pageProps} />
      </StrictlyPublicRoute>
    );
  } else {
    return (
      <>
        <Component {...pageProps} />
      </>
    );
  }
}

function App({ Component, pageProps }) {
  const Layout = Component.Layout || MainLayout;
  const InnerLayout = Component.InnerLayout || DefaultLayout;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.config = config;
    }
  }, []);

  return (
    <Layout>
      <InnerLayout>
        <Protector Component={Component} pageProps={pageProps} />
      </InnerLayout>
    </Layout>
  );
}

App.getInitialProps = () => ({});

export default App;
