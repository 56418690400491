export const Server = {
  endpoint: process.env.NEXT_PUBLIC_ENDPOINT,
  project: process.env.NEXT_PUBLIC_PROJECT,
  secretFriendCollectionID: process.env.NEXT_PUBLIC_SECRET_FRIEND_COLLECTION_ID,
  familyReunionDatabaseID: process.env.NEXT_PUBLIC_FAM_REUNION_DATABASE_ID,
  gamesCollectionId: process.env.NEXT_PUBLIC_GAMES_COLLECTION_ID,
  gameCodesCollectionId: process.env.NEXT_PUBLIC_GAME_CODES_COLLECTION_ID,
  gameParticipantsCollectionId: process.env.NEXT_PUBLIC_GAME_PARTICIPANTS_COLLECTION_ID,
  gamePointsCollectionId: process.env.NEXT_PUBLIC_GAME_POINTS_COLLECTION_ID,
  memeGameStateCollectionId: process.env.NEXT_PUBLIC_MEME_GAME_STATE_COLLECTION_ID,
  memeJudgesCollectionId: process.env.NEXT_PUBLIC_MEME_JUDGES_COLLECTION_ID,
  memePhotoCardsCollectionId: process.env.NEXT_PUBLIC_MEME_PHOTO_CARDS_COLLECTION_ID,
  memeGamePhotoCaptionsCollectionId: process.env.NEXT_PUBLIC_MEME_GAME_PHOTO_CAPTIONS_COLLECTION_ID,
}
