export const routes = {
  login: {
    path: '/login',
    key: 'login',
    value: 'Log In',
  },
  logout: {
    path: '/logout',
    key: 'logout',
    value: 'Logout',
  },
  home: {
    path: '/',
    key: 'home',
    value: 'Home',
  },
  dashboard: {
    path: '/dashboard',
    key: 'dashboard',
    value: 'Dashboard',
  },
  settings: {
    path: '/settings',
    key: 'settings',
    value: 'Settings',
  },
};
