import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useGetUser } from "../../hooks";
import { routes } from '../../routes/routes';

export function ProtectedRoute({ children }) {
  const router = useRouter();
  const [{ user}] = useGetUser();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    let from = router.pathname.replace(/^\/|\/$/g, '');
    if (!user) {
      if (from.includes('[id]')) {
        from = router?.asPath ? router?.asPath : routes.login.path;
      }
      return router.replace({
        pathname: routes.login.path,
        query: { from: from },
      });
    } else if (user) {
      setIsVerified(true);
    }
  }, [user, router]);

  if (isVerified) {
    return children;
  } else {
    return null;
  }
}
