import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useGetUser } from "../../hooks";
import { routes } from '../../routes/routes';

export function StrictlyPublicRoute({ children }) {
  const router = useRouter();
  const [{ user}] = useGetUser();

  useEffect(() => {
    // If signed in and permissions have been retrieved + email has been confirmed, redirect
    // away from Login to either dashboard or the referrer page they were trying to land on
    if (user) {
      if (router.query?.from) {
        router.push(router.query.from);
      } else {
        router.push(routes.dashboard.path);
      }
    }
  }, [user, router]);

  // After creating account and confirming email, status is pending
  // Login screen should render the login form when in pending status
  if (user) {
    return children;
  } else {
    return null;
  }
}
